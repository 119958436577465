import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import HomeLoad from './HomeLoad';
import { Provider } from 'react-redux'
import store from './redux/store.js'
const Root = React.lazy(() =>
  import("./Root")
);

let css_getter = () => {


  if (process.env.REACT_APP_THEME == "WALIYA")
    return "walia.css"
  else if (process.env.REACT_APP_THEME == "MULA")
    return "mula.css"
  else if (process.env.REACT_APP_THEME == "QWICKBET")
    return "qwickbet.css"
  else if (process.env.REACT_APP_THEME == "HIGHLIGHT")
    return "highlight.css"
  else if (process.env.REACT_APP_THEME == "VEGAS")
    return "vegas.css"
  else if (process.env.REACT_APP_THEME == "ABYSSINIA")
    return "abyssinia.css"
  else if (process.env.REACT_APP_THEME == "SMART")
    return "smartbet.css"
  else if (process.env.REACT_APP_THEME == "SAMSONN")
    return "samsonn.css"

  else if (process.env.REACT_APP_THEME == "SCORE")
    return "scorebet.css"
  
  else if (process.env.REACT_APP_THEME == "GISILLABET")
    return "gisilla.css"

  else if (process.env.REACT_APP_THEME == "AHUNBET")
    return "ahunbet.css"
  
  else if (process.env.REACT_APP_THEME == "MARATHON")
    return "marathon.css"
  
  else if (process.env.REACT_APP_THEME == "KWICKBET")
    return "kwickbet.css"
  
  else if (process.env.REACT_APP_THEME == "IKOBET")
    return "ikobet.css"
  
  else if (process.env.REACT_APP_THEME == "ETHIOBET")
    return "ethiobet.css"

  else if (process.env.REACT_APP_THEME == "POWERBET")
    return "powerbet.css"
  else if (process.env.REACT_APP_THEME == "STARBET")
    return "starbet.css"

  }

ReactDOM.render(
  <Provider store={store} >
    <React.Suspense fallback={<HomeLoad />}>
      <div>
        <link rel="stylesheet" type="text/css" href={`/assets/css/${css_getter()}`} />

      </div>
      <Root />
    </React.Suspense>
  </Provider>
  ,
  document.getElementById("root")
);


//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
